import { css, cx } from 'emotion';
import React, { useCallback, useEffect } from 'react';
import closeIcon from '../../assets/closeIcon.svg';

const modalStyle = css`
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%; /* Full width */
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  border-radius: 12px;
`;

const hideStyle = css``;

const showStyle = css``;

const removeDefaultButtonStyle = css`
  background: transparent;
  border: none;
`;

const modalContentStyle = css`
  position: fixed;
  bottom: 0;
  background-color: var(--white);
  width: 100%;
  //-webkit-animation-name: slideIn;
  //-webkit-animation-duration: 0.4s;
  //animation-name: slideIn;
  //animation-duration: 0.4s;
  max-height: 100%;
  display: block;
  border-radius: 12px;
`;

const modalHeaderStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  border-radius: 12px 12px 0 0;
  padding: 0.75rem 1.5rem;
  > h4 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.22em;
    margin-bottom: 0;
  }
`;
const errorModalHeaderStyle = css`
  background-color: rgb(235, 92, 57, 0.12);
  > h4 {
    color: var(--error);
    opacity: 1;
    :before {
      content: '';
      margin-right: 0;
      background-repeat: no-repeat;
      padding-left: 2rem;
    }
  }
`;
const normalModalHeaderStyle = css`
  background-color: var(--primaryWhite);
  > h4 {
    color: var(--primaryBrand);
  }
`;
const bodyStyle = css`
  width: 100%;
  padding: 0 1.5rem 1.5rem 1.5rem;
`;

export const Modal = (props) => {
  const {
    title,
    children,
    show,
    handleClose,
    customModalStyle,
    customBodyStyle,
    showHeader = 'true',
    showCloseIcon = true,
    errorMessage = false,
    icon,
  } = props;
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    window.onclick = (event) => {
      const modal = document.getElementById('myModal');
      if (event.target === modal) {
        handleClose();
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, handleClose]);
  const addIconStyle = css`
    :before {
      content: '';
      background-image: url(${icon});
      margin-right: 0;
      background-repeat: no-repeat;
      padding-left: 2rem;
    }
  `;

  return (
    <div
      id="myModal"
      className={show ? cx(modalStyle, showStyle) : cx(modalStyle, hideStyle)}
    >
      <div
        className={
          customModalStyle
            ? cx(modalContentStyle, customModalStyle)
            : modalContentStyle
        }
      >
        {showHeader === 'true' && (
          <div
            className={cx(
              modalHeaderStyle,
              errorMessage ? errorModalHeaderStyle : normalModalHeaderStyle
            )}
          >
            <h4 className={icon && addIconStyle}>{title}</h4>
            <button
              className={removeDefaultButtonStyle}
              onClick={handleClose}
              type="button"
            >
              {showCloseIcon && <img src={closeIcon} alt="close" />}
            </button>
          </div>
        )}
        <div className={cx(bodyStyle, customBodyStyle)}>{children}</div>
      </div>
    </div>
  );
};
