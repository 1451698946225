import React from 'react';
import { css, cx } from 'emotion';

const buttonStyle = css`
  text-align: center;
  padding: 0.75rem 2.25rem;
  font-size: 1rem;
  line-height: 1.12em;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.14em;
    padding: 0.5rem 0.75rem;
  }
`;

const primaryButton = css`
  background: var(--primaryBrand);
  color: var(--white);
  border: 2px solid transparent;
  &:hover {
    background: var(--insyncOrange);
  }
`;

const secondaryButton = css`
  background: white;
  border: 2px solid var(--primaryBrand);
  color: var(--primaryBrand);
  &:hover {
    color: var(--insyncOrange);
    border-color: var(--insyncOrange);
  }
`;
const disabledStyle = css`
  background-color: var(--platinum);
  &:hover {
    background-color: var(--platinum);
  }
`;

export const Button = (props) => {
  const { label, onClick, secondary, type = 'button', disabled } = props;
  return (
    <button
      className={cx(
        buttonStyle,
        secondary ? secondaryButton : primaryButton,
        disabled ? disabledStyle : ''
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
};
