const COVERMYTOOLS = 'COVERMYTOOLSREVISED';
const BEAUTY = 'INSYNCBEAUTYREVISED';
const AXIS_CLINICAL = 'AXISCLINICAL';
const AXIS_AESTHETIC = 'AXISAESTHETIC';
const PBIS = 'PBISParkLeisureHome';
const BEAZLEYAESTHETICS = 'BEAZLEYAESTHETICS';
const COVEATRADESMAN = 'CoveaTradesmanInsurance';
const AXAPI = 'AXAProfessionalPI';
const FARADAYLIABILITY = 'FaradayLiability';
const AXIS_PROPERTY_OWNERS = 'AXISPropertyOwners';
const AXISPI = 'AXISCombined';

export const PRODUCTS = {
  COVERMYTOOLS,
  BEAUTY,
  AXIS_CLINICAL,
  AXIS_AESTHETIC,
  PBIS,
  BEAZLEYAESTHETICS,
  COVEATRADESMAN,
  AXIS_PROPERTY_OWNERS,
  AXAPI,
  FARADAYLIABILITY,
  AXISPI,
};
export const schemeName = {
  [PRODUCTS.COVERMYTOOLS]: {
    name: 'Covermytools',
  },
  [PRODUCTS.BEAUTY]: {
    name: 'Insync Beauty',
  },
  [PRODUCTS.AXIS_CLINICAL]: {
    name: 'AXIS Clinical',
  },
  [PRODUCTS.AXIS_AESTHETIC]: {
    name: 'AXIS Aesthetic',
  },
  [PRODUCTS.PBIS]: {
    name: 'Insync PBIS',
  },
  [PRODUCTS.BEAZLEYAESTHETICS]: {
    name: 'Beazley Aesthetics',
  },
  [PRODUCTS.COVEATRADESMAN]: {
    name: 'Covea Trades and Professions',
  },
  [PRODUCTS.FARADAYLIABILITY]: {
    name: 'Faraday Liability',
  },
  [PRODUCTS.AXIS_PROPERTY_OWNERS]: {
    name: 'AXIS Property Owners',
  },
  [PRODUCTS.AXAPI]: {
    name: 'AXA Professional',
  },
  [PRODUCTS.AXISPI]: {
    name: 'AXIS Professionals Combined'
  }
};
