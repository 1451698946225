import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import insyncLogo from '../../assets/insyncLogo.svg';

const headerStyle = css`
  grid-area: header;
  position: sticky;
  top: 0;
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.25rem;
  z-index: 4;
  border-bottom: 1px solid var(--primaryBrand);
`;

const logoStyle = css`
  height: 1.75rem;
  @media (max-width: 768px) {
    height: 1.125rem;
  }
`;

const bannerStyle = css`
  background: var(--primaryBrand);
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  color: white;
`
const headerWrapper = css`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Header = () => (
  <div className={headerWrapper}>
    <header className={headerStyle}>
      <Link to="/">
        <img src={insyncLogo} alt="logo" className={logoStyle} />
      </Link>
    </header>
    <div className={bannerStyle}><b>Attention</b>: Upcoming changes will automate policy/policyholder data migration. This form will soon become obsolete. Please watch for further updates. </div>
  </div>
);
