import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { css } from 'emotion';
import * as Yup from 'yup';
import get from 'lodash/get';
import { Loader } from '../components/Loader';
import useFirebase from '../Firebase/useFirebase';
import { InputField } from '../components/InputField';
import { Button } from '../components/Button';
import { Modal } from '../components/Modal';
import { schemeName } from '../../constants/products';
import errorIconUrl from '../../assets/errorIcon.svg';
import { AutocompleteSearch } from '../components/AutocompleteSearch';

const rightButtonContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  @media (max-width: 768px) {
    > button {
      width: 100%;
    }
  }
`;

const modalContentContainerStyle = css`
  display: grid;
  grid-template-columns: 100%;
  > p {
    font-size: 1rem;
    line-height: 1.5em;
    color: var(--darkGray);
    margin-bottom: 0.875rem;
  }
`;
const errorStyle = css`
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
  > img {
    height: 1.5rem;
    margin-right: 0.375rem;
  }
  > span {
    padding-left: 0.375rem;
    font-size: 0.875rem;
    color: var(--red);
  }
`;
const modalButtonContainerStyle = css`
  margin: 2.25rem 0 2.25rem auto;
  float: right;
`;
const customModalStyle = css`
  width: 47.75rem;
  top: 40%;
  left: 50%;
  bottom: unset;
  transform: translate(-50%, -50%);
  > div {
    padding: 1.5rem 2.25rem;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;
const mainSectionStyle = css`
  padding: 1rem 2.25rem;
  > div {
    margin-bottom: 2.25rem;
  }
  > div:first-child {
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    > div:first-child {
      margin-bottom: 2.25rem;
    }
  }
`;

const loaderContainerStyle = css`
  height: calc(100vh - 5rem);
`;

const mainTitleStyle = css`
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 1.2em;
  text-align: center;
  color: var(--primaryBrand);
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
`;
const modalTextStyle = css`
  color: var(--darkGray);
  a {
    color: var(--darkGray);
  }
`;

const dscStyle = css`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #404042;
`;

const schemes = [];
Object.keys(schemeName).forEach((key) => {
  schemes.push({ code: key, value: get(schemeName, `${key}.name`) });
});

const initialValues = {
  policyReference: '',
  firstName: '',
  email: '',
  product: '',
  customerType: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email.')
    .required('Email is required field.'),
  firstName: Yup.string().required('First name is required field.'),
  policyReference: Yup.number().required('Policy reference is required field.'),
  product: Yup.string().required('You need to choose product.'),
  customerType: Yup.string().required('Choose customer type'),
});

export const MainScreen = () => {
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [policyRef, setPolicyRef] = useState(null);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      // setting values for email and policyRef for showing it in modal.
      // Formik on submit deletes all input in fields
      setEmail(values.email);
      setPolicyRef(values.policyReference);
      const sessionId = get(window, 'AutopilotAnywhere.sessionId', '');
      await firebase.doMovePolicyToAPI(values, sessionId);
      setError(false);
      setErrorMessage(undefined);
      setIsLoading(false);
      setIsModalOpen(true);
    } catch (err) {
      setError(true);
      setErrorMessage(err.message);
      console.error(err.message);
      setIsLoading(false);
    }
  };
  const customerTypes = [
    { code: 'NEW_CUSTOMER', value: 'New customer' },
    { code: 'MIGRATED_CUSTOMER', value: 'Migrated customer' },
  ];
  if (isLoading) {
    return (
      <div className={loaderContainerStyle}>
        <Loader message="Please wait." />
      </div>
    );
  }
  return (
    <section className={mainSectionStyle}>
      <div>
        <h1 className={mainTitleStyle}>Customer Portal Registration Form</h1>
        <p className={dscStyle}>
          Please complete this form for any policies created in ICE Policy to
          generate their customer portal registration email.
          <br />
          You do not need to complete this form for quote and buy site policies
          as they will have automatically been sent the email.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <div>
              <InputField name="email" id="email" placeholder="E-mail" />
              <InputField
                name="firstName"
                id="firstName"
                placeholder="First name"
              />
              <InputField
                name="policyReference"
                id="policyReference"
                placeholder="Policy reference"
              />
              <AutocompleteSearch
                name="product"
                placeholder="Scheme name"
                options={schemes}
                returnCode
              />
              <AutocompleteSearch
                name="customerType"
                placeholder="Customer type"
                options={customerTypes}
                returnCode
              />
            </div>
            {error && (
              <div className={errorStyle}>
                <img src={errorIconUrl} alt="error icon" />
                <span>
                  {errorMessage ||
                    'An error occurred. Please check your form details and try again.'}
                </span>
              </div>
            )}
            <div className={rightButtonContainerStyle}>
              <Button
                onClick={formik.handleSubmit}
                label="Update"
                type="submit"
              />
            </div>
            {isModalOpen && (
              <Modal
                handleClose={() => setIsModalOpen(false)}
                show={isModalOpen}
                customModalStyle={customModalStyle}
                title="Success"
              >
                <div className={modalContentContainerStyle}>
                  <p className={modalTextStyle}>
                    Customer portal registration email for policy{' '}
                    <b>{policyRef}</b> has been sent to{' '}
                    <a href={`mailto:${email}`}>
                      <b>{email}</b>
                    </a>
                  </p>
                </div>
                <div className={modalButtonContainerStyle}>
                  <Button
                    label="Close"
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                  />
                </div>
              </Modal>
            )}
          </Form>
        )}
      </Formik>
    </section>
  );
};
