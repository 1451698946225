import React, { useState, Fragment, useEffect, useRef } from 'react';
import { css, cx } from 'emotion';
import { useFormikField } from '../../utils/use-formik-field';

const optionStyle = css`
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--dark);
  cursor: pointer;
  position: relative;
  display: block;
  padding: 1rem 0.914rem;
`;

const optionDividerStyle = css`
  width: 100%;
  position: absolute;
  border: 1px solid var(--gray);
`;

const inputContainerStyle = css`
  margin: 0.3125rem 0 0.5rem 0;
`;

const wrapperStyle = css`
  margin: 0 0 1rem 0;
`;

const labelStyle = css`
  width: 90%;
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  line-height: 1rem;
  font-size: 1rem;
  color: var(--gray);
  pointer-events: none;
  padding: 0.5rem 0;
`;

const hasValueState = css`
  label {
    color: var(--gray);
    background-color: var(--white);
    width: auto;
    transition: all 0.25s ease-out;
    padding: 0 0.375rem;
    transform: translate(-0.5rem, -1.9rem);
    font-size: 0.75rem;
    z-index: 2;
  }
`;
const customInputContainer = css`
  position: relative;
  border: 2px solid var(--platinum);
  flex: 1;
  height: 2.875rem;
`;
const brandBorderStyle = css`
  border: 2px solid var(--primaryBrand);
`;

const errorStyle = css`
  color: var(--red);
  font-size: 0.75rem;
  line-height: 1.5em;
  margin-left: 1rem;
`;

const errorBorderStyle = css`
  border: 2px solid var(--red);
`;

const customInputStyle = css`
  width: 100%;
  height: 100%;
  padding: 0.625rem 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.375rem;
  border: none !important;
  border-radius: 0;
  background-color: transparent;
  outline: none !important;
  color: var(--arsenic);
  left: 0;
  top: 0;
  position: relative;
  &:focus {
    + label {
      background-color: var(--white);
      width: auto;
      color: var(--primaryBrand);
      transition: all 0.25s ease-out;
      padding: 0 0.375rem;
      transform: translate(-0.5rem, -1.9rem);
      font-size: 0.75rem;
      z-index: 2;
    }
    ~ .borderDiv {
      outline-color: var(--primaryBrand);
    }
  }
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
`;

const optionContainerStyleOpen = css`
  opacity: 1;
  visibility: visible;
  pointer-events: all;
`;

const stretchedContainerStyle = css`
  display: flex;
  align-items: stretch;
  position: relative;
`;
const optionContainerStyle = css`
  position: absolute;
  display: block;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  box-shadow: 0 10px 10px rgba(64, 64, 66, 0.24);
  border-top: 0;
  background: var(--white);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 5;
  max-height: 25rem;
  overflow-y: auto;
`;

export const AutocompleteSearch = (props) => {
  const { name, placeholder, options, returnCode } = props;
  const {
    isInErrorState,
    touched,
    errorMessage,
    focused,
    onFocus,
    setValue,
    onBlur,
    value,
  } = useFormikField(name);
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState(options);
  const [input, setInput] = useState(value.value || '');
  const ref = useRef(null);
  const refContainer = useRef(null);

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !refContainer.current.contains(event.target)
    ) {
      setIsComponentVisible(false);
      onBlur(name);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (isComponentVisible === false) {
      const finalValue = options.find(
        (option) => option.value.toLowerCase() === input.toLowerCase()
      );
      if (finalValue) {
        setInput(finalValue.value);
        if (returnCode) {
          setValue(finalValue.code);
        } else {
          setValue({
            code: finalValue.code,
            value: finalValue.value,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [isComponentVisible]);

  const onChange = (e) => {
    const inputValue = e.currentTarget.value;
    const newFilteredSuggestions = options.filter(
      (suggestion) =>
        suggestion.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
    setActive(0);
    setFiltered(newFilteredSuggestions);
    setIsComponentVisible(true);
    if (returnCode) {
      setValue('');
    } else {
      setValue({ code: '', value: '' });
    }

    setInput(e.currentTarget.value);
  };

  const onClick = (e, option) => {
    setActive(0);
    setFiltered([]);
    setIsComponentVisible(false);
    setInput(e.currentTarget.innerText);
    if (returnCode) {
      setValue(option.code);
    } else {
      setValue({
        code: option.code,
        value: option.value,
      });
    }

    onBlur(e);
  };

  const renderAutocomplete = () => {
    if (isComponentVisible && focused) {
      if (filtered.length) {
        return (
          <ul
            className={cx(
              optionContainerStyle,
              focused && optionContainerStyleOpen
            )}
            id="autocomplete-options--destination"
            role="listbox"
            ref={ref}
          >
            {filtered.map((suggestion, index) => (
              <Fragment key={suggestion.code}>
                <li
                  role="option"
                  tabIndex="-1"
                  aria-selected={index === active}
                  data-option-value={suggestion.code}
                  id={suggestion.code}
                  className={optionStyle}
                  onClick={(e) => onClick(e, suggestion)}
                  onKeyPress={(e) => onClick(e, suggestion)}
                >
                  {suggestion.value}
                </li>
                <span className={optionDividerStyle} />
              </Fragment>
            ))}
          </ul>
        );
      }
      return (
        <span className={errorStyle} aria-live="polite" role="status">
          <em>Nothing found</em>
        </span>
      );
    }
    return <></>;
  };
  return (
    <div className={wrapperStyle}>
      <div className={inputContainerStyle}>
        <div className={stretchedContainerStyle}>
          <div
            className={cx(
              customInputContainer,
              (input.length > 0 || focused) && hasValueState,
              focused && brandBorderStyle,
              isInErrorState && touched && !focused && errorBorderStyle
            )}
          >
            <input
              aria-owns="autocomplete-options--destination"
              autoCapitalize="none"
              autoComplete="off"
              aria-autocomplete="list"
              role="combobox"
              aria-controls="IDREF"
              aria-expanded="false"
              type="text"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={isComponentVisible ? undefined : onBlur}
              value={input}
              id={name}
              name={name}
              className={customInputStyle}
              ref={refContainer}
            />
            <label className={labelStyle} htmlFor={name}>
              {placeholder}
            </label>
            {renderAutocomplete()}
          </div>
        </div>
        {isInErrorState && touched && !focused && (
          <span className={errorStyle}>
            {errorMessage.value || errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};
