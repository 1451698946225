import React from 'react';
import './index.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { css } from 'emotion';
import { Header } from './components/components/Header';
import { Footer } from './components/components/Footer';
import { MainScreen } from './components/Main/Main';

const bodyStyle = css`
  min-height: calc(100vh - 10px);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
`;
const contentContainer = css`
  grid-area: main;
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const App = () => (
  <Router>
    <div className={bodyStyle}>
      <Header />
      <main className={contentContainer}>
        <Switch>
          <Route exact path="/" component={MainScreen} />
          <Redirect from="*" to="/" />
        </Switch>
      </main>
      <Footer />
    </div>
  </Router>
);
